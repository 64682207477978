.audio-card {
  padding: 10px;
  font-weight: 500;
  border-radius: 5px;
  &.selected {
    background-color: #001e5a;
    color: #fff;
    .ant-progress-inner {
      background: rgba(0, 0, 0, 0.5) !important;
    }
  }
}
