.sidebar {
  background: none !important;
  padding: 16px;
  height: 100vh;
  overflow-y: auto;
  border-right: solid 1px #c3966e;

  .ant-layout-sider-children {
    .custom-menu {
      background: transparent;
      border: none;
    }
  }

  .ant-menu-sub .ant-menu-title-content,
  .ant-menu-item,
  .ant-menu-submenu-title {
    border-radius: 25px !important;
  }

  &.ant-layout-sider-collapsed {
    .footer-menu {
      ul {
        padding: 0;
        li {
          flex-direction: column;
          align-items: center;
        }
      }

      .ant-menu-item-icon {
        margin-top: 0;

        .custom-icon {
          display: flex;
        }
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 5px 8px !important;
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        background-color: #001e5a;
        color: #fff;
      }

      &.profile-menu .ant-menu-submenu-title {
        background-color: #c05f3c;
        color: #fff;
      }
    }
  }
}
