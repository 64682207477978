.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-box{
      background-color: #5A86BF;
      border-radius: 5px;
      padding: 5px;
      .loader{
        margin: 15px 5px 5px 5px;
        color: #fff;
        border-radius: 5px;
        .bubble {
          background-color: #fff;
        }
      }
    }
    // height: 100vh;
  }
  
  .loader {
    display: flex;
    align-items: flex-end;
  }
  
  .bubble {
    width: 9px;
    height: 9px;
    margin: 0 5px;
    background-color: #5A86BF;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate ease-in-out;
  }
  
  .bubble:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bubble:nth-child(3) {
    animation-delay: 0.4s;
  }
  @keyframes bounce {
    to {
      transform: translateY(-12.5px);
    }
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }