body {
  // p,
  // li {
  //   font-size: 16px !important;
  //   line-height: 25px;
  // }
  p {
    line-height: 1.5rem !important;
  }
  .ant-card .ant-card-body {
    padding: 20px !important;
  }
  //

  //
  .read-more {
    font-weight: 600 !important;
    font-size: 12px !important;
  }
}
.page-bg-left {
  background: #f2f0e9 url('../public/images/page-bg-left.png') no-repeat top
    left !important;
}
.page-bg-right {
  background: url('../public/images/page-bg-right.png') no-repeat top right !important;
}
.App {
  text-align: center;
}
// .ant-layout {
//   font-size: 20px;
//   line-height: 34px;
// }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.page-heading,
.sub-heading,
.ant-collapse-header-text,
.scenario-sub-heading,
.scenario-heading,
.page-sub-heading,
.heading {
  font-family: 'Exo', sans-serif;
}
.page-heading {
  font-family: 'Exo', sans-serif;
  font-optical-sizing: auto;
  font-size: 26px !important;
  font-weight: 700 !important;
  font-style: normal;
}
// .page-sub-heading,
// .artitle-heading {
//   // font-size: 24px !important;
//   // font-weight: 600 !important;
//   //text-transform: capitalize;
// }
img.sparkle {
  position: relative;
  top: -10px;
  right: -5px;
}
.knowledge-base-heading {
  font-size: 18px !important;
  font-weight: 700 !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.layout-content {
  overflow: initial;
}

.ant-table-row.highlight,
.ant-table-row.highlight .ant-table-cell-row-hover {
  background-color: #ccc !important;
}
.ant-image-preview-img {
  background-color: #fff !important;
}
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-button-noti {
  border-radius: 30px;
}

.ant-list-item {
  &.active {
    background-color: #282c34;
    color: #fff;
  }
}
// .ant-list-items {
//   .ant-list-item{
//     &:hover{
//       background-color: #001E5A;
//       color: #fff;
//       border-radius: 5px;
//     }
//   }
// }
.logo {
  padding: 0 10px 10px;
  flex-grow: 1;
}

.hero-icon-24 {
  width: 24px;
}
.hero-icon-30 {
  width: 30px;
}
.hero-icon-12 {
  width: 12px;
}
.hero-icon-18 {
  width: 18px;
}
.noti-icon {
  height: 150px;
  width: 100px;
}

.footer-menu {
  margin-bottom: 10px;
  ul {
    margin: 0;
    padding-left: 27px;
    li {
      list-style-type: none;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      &.pointer {
        cursor: pointer;
      }
      .custom-icon + span {
        margin-inline-start: 10px;
      }
    }
  }
}
.pointer-css {
  cursor: pointer;
}

.ant-menu-item-only-child {
  padding-left: 28px !important;
}

.logout {
  color: #05050699;
}
.secondary-menu {
  .ant-menu-item {
    padding: 10px 15px !important;
    height: auto !important;
    line-height: 1.5em !important;
    white-space: normal !important;
    border-radius: 8px !important;
    //font-size: 18px;
  }
}
.footer-menu-item {
  &.ant-menu-item {
    height: auto !important;
    white-space: normal !important;
    line-height: 1.5em !important;
  }
}
.collapse-panel-custom {
  background: none;
  .ant-collapse-header-text {
    font-size: 14px;
    font-weight: 600;
  }
  .ant-collapse-item {
    border: none;
    .ant-collapse-header {
      color: #656358;
    }
    &.ant-collapse-item-active {
      color: #1d322d;

      background-color: #fff;
      //border: solid 1px #b5cae7;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      box-shadow: 0px 4px 6px 0px #222a350a !important;

      border: 1px solid #d3d1c9 !important;
    }
  }
  .markdown-content {
    font-size: 12px;
  }
  .markdown-content > :first-child {
    margin-top: 0px;
  }
}
.tab-button {
  // border: solid 1px #9B6F4980;
  //border: solid 1px rgba(0, 30, 90, 0.5);
  //background: #F3EAE2;
  color: #c05f3c;
  border: none;
  background: none;
  box-shadow: none;

  padding-inline-start: 16px;
  padding-inline-end: 16px;

  line-height: 1.5;
  cursor: pointer;
  &:hover {
    background: #f0eee5;
    color: #c05f3c !important;
  }
  &.curved-border {
    border-radius: 32px;
  }
  &.active {
    color: #fff;
    // border: solid 1px #9B6F4980;
    background: #c3966e;

    //font-weight: 600;

    .ant-btn-icon {
      color: #fbc05a;
      -webkit-filter: drop-shadow(
        0px 2px 5px rgba(0, 0, 0, 0.25)
      ); /* Safari & older versions of Chrome */
      filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25)); /* Standard */
    }
    &:hover {
      background: #c3966e !important;
      color: #fff !important;
    }
  }
}
.all-button {
  // border: solid 1px #9B6F4980;
  border: solid 1px rgba(0, 30, 90, 0.5);
  //background: #F3EAE2;
  color: #1d322d;

  padding-inline-start: 16px;
  padding-inline-end: 16px;

  line-height: 1.5;
  cursor: pointer;
  &.curved-border {
    border-radius: 32px;
  }
  &.active {
    color: #fff;
    // border: solid 1px #9B6F4980;
    background: #001e5a;
    //font-weight: 600;
    &:hover {
      background: #001e5a !important;
      color: #fff !important;
    }
  }
}
.filters {
  .seperator {
    margin: 0 5px;
    padding-bottom: 3px;
    color: #b4a49980;
  }
  .btn {
    border-radius: 5px;
    padding: 2px 6px;
    &.active {
      background-color: #001e5a;
      color: #fff;
    }
    //padding-inline-start: 16px;
    //padding-inline-end: 16px;

    cursor: pointer;
  }
}

.filters .priority-tabs {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(238, 235, 218, 0.2), rgba(238, 235, 218, 0.2));
  border: 1px solid #d9d9d9;
  &.active {
    background: #001e5a;
    color: #fff;
    border: 1px solid #9b6f49;
  }
}
.filters .ant-picker-range {
  padding: 5px 11px 5px;
}
.ant-picker-panel-container,
.ant-dropdown-menu {
  background: #fbf6f3 !important;
}

.footer-menu .profile-menu .ant-menu-submenu-title {
  padding-inline: 24px;
}
.footer-menu .ant-menu-item {
  display: flex;
}
.footer-menu.ant-menu-inline-collapsed .ant-menu-item {
  display: block;
}

.ant-menu-submenu-popup .ant-menu-item-selected,
.ant-dropdown-menu-item-active {
  background-color: #f0e2d5 !important;
  color: #000 !important;
}
.ant-menu-submenu-popup .ant-menu-sub {
  background-color: #fbf6f3 !important;
}
.video-priority {
  margin-right: 8px;
  .ant-badge-status-dot {
    width: 10px !important;
    height: 10px !important;
    //top: -2px;
  }
}
.priority-tabs {
  .btn.high.active {
    .ant-badge-status-dot {
      box-shadow: #d9534f 0 0 3px 2px;
    }
  }
  .btn.medium.active {
    .ant-badge-status-dot {
      box-shadow: #f0ad4e 0 0 3px 2px;
    }
  }
  .btn.low.active {
    .ant-badge-status-dot {
      box-shadow: #5cb85c 0 0 3px 2px;
    }
  }
}
//LMS
.quiz-question {
  .correct-answer {
    color: #389e0d;
  }
  .incorrect-answer {
    color: #cf1322;
  }
  /*&.admin {
    label,
    textarea,
    .option-response,
    .subjective-response {
      font-size: 20px;
    }
  }*/
}
.course-card {
  width: 220px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  .cover-title {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 15px;
    .title {
      margin: 0;
      color: #fff;
      text-shadow: 2px 2px #0000004a;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .image-container {
    min-height: 220px;
    padding-bottom: 20px;
  }
  .cover-image {
    width: 100%;
  }
  .play-btn {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
    border: solid 5px #f0e2d5;
    background: #f3eae2;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px #001e5a14;
  }
  .card-footer {
    background: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px -1px 2px 0px #0000001a;
  }
}

.course-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

//end LMS
/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
@import './styles/loaderStyle.scss';
.tab-button-recent {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-right: 8px;
}

.tab-button.selected {
  background-color: #001e5a; /* Dark blue background */
  color: #fff; /* White text */
  border-color: #001e5a; /* Dark blue border */
}
.menu-notification {
  //box-shadow: 2px ;
  border-radius: 6px;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 8px;
}
.pointer {
  cursor: pointer;
}
.content-center {
  display: flex;
  justify-content: center;
}
.fixed-pagination {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  // background: #F9EFE8;
  background: transparent;
  padding: 10px 0 0 0;
}
.main-notification {
  border: 2px solid red;
  display: flex;
  justify-content: center;
  padding: 50px;
  align-items: center;
  margin-left: 200px;

  margin-right: 200px;
  margin-top: 80px;
}
/* NotificationsPage.css */

.notifications-container {
  padding: 16px;
  background-color: #f7f8fa;
  height: 100vh;
}

.notifications-header {
  margin-bottom: 12px;
}

.notifications-header .ant-btn-primary {
  background-color: #001529;
  border-color: #001529;
}

.notifications-header .ant-btn-link {
  color: #1d322d99;
}

.notification {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.notification.success {
  background-color: #f0f8e2;
  border-left: 5px solid #a3c45a;
}

.notification.failure {
  background-color: #f8e2e2;
  border-left: 5px solid #c45a5a;
}

.notification .message {
  font-size: 16px;
  margin: 0;
}
.notification-center {
  width: 400px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header button {
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.header button.active {
  font-weight: bold;
  text-decoration: underline;
}

.header .mark-all-read {
  background-color: #ddd;
  border-radius: 5px;
  padding: 5px 10px;
}

.notification-spacing {
  display: flex;
  justify-content: center;
}
.notification-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.badge {
  background-color: #f00;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  margin-left: 5px;
}

.notification-ui {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.notification .timestamp {
  font-size: 12px;
  color: #888;
  display: block;
  margin-top: 5px;
}

.notification.read {
  opacity: 0.6;
}

.notification.unread {
  font-weight: bold;
}

.notifications-list {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.empty-notifications {
  text-align: center;
  padding: 48px 0;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item.unread {
  background-color: #e6f7ff;
}
.assessment-course-card {
  &.active {
    cursor: pointer;
  }
}
.custom-table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #c3966e !important;
  background: none;
  text-transform: uppercase;
}
.custom-table.ant-table-wrapper .ant-table-thead > tr > th,
.custom-table.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none;
}
.custom-table.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background: none;
}

.no-border-bottom .ant-card-head {
  border-bottom: 0;
}

.admin-tab {
  padding: 8px;
  gap: 10px;
  border-radius: 8px;
  border: solid 1px #d9d9d9;
  background-color: #eeebda33;
  span {
    display: block;
    padding: 2px;
  }
  span.active {
    background-color: #001e5a;
    color: #fff;
    border-radius: 5px;
  }
}
ul.course-list {
  list-style-type: none;
  padding: 0;
  li {
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    &.active {
      background-color: #1a356b;
      color: #fff;
    }
  }
}

.custom-list {
  font-size: 16px;
  padding: 0;
  li {
    display: flex;
    margin-bottom: 10px;
    &::before {
      content: '';
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: 3px;
    }
    &.color-completed {
      &::before {
        background-color: #9b6f49;
      }
    }
    &.color-inprogress {
      &::before {
        background-color: #c3966e;
      }
    }
    &.color-not-started {
      &::before {
        background-color: #dbbb9e;
      }
    }
    &.color-reviewed {
      &::before {
        background-color: #b5cae7;
      }
    }
    &.color-not-reviewed {
      &::before {
        background-color: #4566a9;
      }
    }
  }
}
.text-red {
  color: #d9534f;
}
.text-green {
  color: #389e0d;
}
.text-default {
  color: #001e5a;
}

.notifications-menu {
  &.ant-dropdown-menu {
    background-color: #fbf6f3;
    .ant-dropdown-menu-item {
      white-space: normal;
      &.ant-dropdown-menu-item-active {
        background: none !important;
      }
    }
  }
}
.ant-select.active {
  .ant-select-arrow {
    color: #fff;
  }
  .ant-select-selector,
  .ant-select-selection-search,
  .ant-select-selection-item {
    background-color: #001e5a !important;
    color: #fff;
  }
}
.ant-select-dropdown {
  background-color: #fbf6f3;
  .ant-select-item-option-active {
    background-color: #f3eae2 !important;
  }
  .ant-select-item-option-selected {
    background-color: #f0e2d5 !important;
  }
}
.video-tab-content {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.ant-btn > div {
  display: none !important; //disable wave effect
}

.max-width {
  max-width: 1200px !important;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
//.custom-table.ant-table-wrapper .ant-table-thead > tr > th {
.custom-table {
  &.users-list {
    th {
      font-size: 20px;
    }
    td:nth-child(1),
    td:last-child a {
      font-weight: 600;
    }
    td:last-child a {
      color: #001e5a !important;
    }
  }
  &.progress-list,
  &.assessment-list {
    th {
      font-size: 16px;
    }
    td:nth-child(2) {
      font-weight: 600;
    }
  }
}
.item-card {
  border: solid 1px #d3d1c9;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(235, 238, 249, 0.2), rgba(235, 238, 249, 0.2));
  box-shadow: 0px 4px 6px 0px #222a350a !important;
}
.profile-btn,
.profile-btn:hover {
  background: #c05f3c !important;
  color: #fff !important;
  font-weight: bold !important;
  border: none;
  span {
    text-align: left;
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ant-select-item-option-content {
  //font-size: 20px;
}

.Typewriter__cursor {
  display: none;
}
.show-detail-icon {
  margin-bottom: 15px;
  margin-left: 36px;
}

.player-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Aspect ratio of 16:9 (9 / 16 * 100) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-list .player-wrapper {
  max-width: 800px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: none;
  }
}
.ant-dropdown-menu{
  max-height: 400px;
  overflow-y: auto !important;
}