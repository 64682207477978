body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.88);
}
/*active color of dropdown sub-memu*/

.ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #f3eae2;
  color: #001e5a;
}
.ant-menu-light.footer-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #001e5a;
  color: #fff;
}
.ant-menu-light .profile-menu.ant-menu-submenu .ant-menu-submenu-title {
  background-color: #c05f3c;
  color: #fff;
  border-radius: 5px !important;
}
