.login-bg {
  background-image: linear-gradient(
      0deg,
      rgba(242, 240, 233, 0.8),
      rgba(242, 240, 233, 0.8)
    ),
    linear-gradient(
      65.54deg,
      rgba(232, 229, 222, 0) 45.56%,
      rgba(232, 229, 222, 0.7) 100.36%
    ),
    url('../../../public/images/bg-overlay.png');

  background-size: cover;
  background-position: center;
  label {
    font-weight: 500;
    &::before {
      content: '' !important;
    }
  }
}
.login-overlay {
  background-image: url('../../../public/images/login-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
