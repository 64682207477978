.ask-me-card .ant-card-body {
  min-width: 8em;
}

.main-chat-content {
  padding-right: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  &.initial-screen {
    flex-direction: column;
  }
}

.message-footer {
  padding: 15px 20px;
}

.chat-message {
  display: flex;
  column-gap: 10px;
  margin-bottom: 15px;
  width: 100%;
  line-height: 1.5rem;
  color: #1d322d;

  &.message-user {
    flex-direction: row-reverse;
    align-self: flex-end;

    .query-response {
      background-color: #eaeef8 !important;
    }
  }

  .Typewriter__wrapper > div > *:first-child,
  .Typewriter__wrapper > *:first-child,
  .markdown-content > div > *:first-child,
  .markdown-content > *:first-child {
    margin-top: 0;
  }

  .markdown-content > ul,
  .Typewriter__wrapper > ul {
    padding-left: 15px;
  }

  .query-response {
    background-color: #f5e7dd;
    border: solid 1px #b4a499;
    padding: 12px 18px;
    border-radius: 1.5rem;
  }

  .markdown-content,
  .Typewriter {
    > *:last-child {
      margin-bottom: 0;
    }
  }

  &.bot .query-response {
    background-color: transparent;
    border: none;
    padding: 0;
    flex-grow: 1;

    .markdown-content,
    .Typewriter {
      margin-bottom: 10px;
    }
  }
}

.scroller {
  height: calc(100vh - 190px); // scroll to bottom needs specific height
}

.custom-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: #fff;
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.25);
}
.Typewriter__cursor {
  display: none;
}
.auto-suggest-input {
  box-shadow: 0 0 0 3px #0000000d !important;
  &:focus-within {
    border: solid 1px #69c0ff;
    box-shadow: 0 0 0 3px #0c72bb26 !important;
  }
  border-color: #c6c6c8;
}
